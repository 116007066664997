import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "bootstrap/dist/css/bootstrap.css";
import './fonts.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown, faArrowLeft, faArrowRight, faRedo, faUndo, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

function App() {
  const cropperRef = useRef(null);
  const [image, setImage] = useState();
  const [croppedImage, setCroppedImage] = useState();
  const [downloadUrl, setDownloadUrl] = useState();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [fileName, setFileName] = useState("cropped");
  const [errorMessage, setErrorMessage] = useState();

  const onFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const extension = file.name.split('.').pop().toLowerCase();
      if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'jpe') {
        setErrorMessage('Only jpg, jpeg, and jpe formats are supported.');
        return;
      }
      setErrorMessage(null);
      const reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      const base64url = croppedCanvas.toDataURL("image/jpeg");
      setCroppedImage(base64url);

      setDimensions({ width: croppedCanvas.width, height: croppedCanvas.height });

      croppedCanvas.toBlob((blob) => {
        setDownloadUrl(URL.createObjectURL(blob));
      }, "image/jpeg");
    }
  };
  
  const moveCrop = (x, y) => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (cropper) {
      cropper.move(x, y);
    }
  };
  
  const rotateCrop = (degree) => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (cropper) {
      cropper.rotate(degree);
    }
  };

  const zoomCrop = (value) => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (cropper) {
      cropper.zoom(value);
    }
  };
  
  const setAspectRatio = (ratio) => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (cropper) {
      cropper.setAspectRatio(ratio);
    }
  };
  
  const resetCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (cropper) {
      cropper.reset();
    }
  };

  return (
    <div className="container-xxl">
	  <h1 className="text-center" style={{ fontFamily: 'Bebas Neue', fontSize: '2rem' }}>Fast Image Cropper for PBC</h1>
	  <p className="text-center" style={{ fontFamily: 'Roboto', fontSize: '1rem' }}>
		A fast and efficient image cropping tool for PBC. Learn more <a href="https://pbc.xxx/wiki/PBC:Images#Headshot/Portrait_Image_Requirements">PBC:AR</a>
	  </p>
      <div className="my-3">
        <input type="file" onChange={onFileChange} className="form-control" accept="image/jpeg,image/jpg,image/jpe" />
        {errorMessage && (
          <div className="alert alert-danger mt-2">{errorMessage}</div>
        )}
		{image && (
		  <>
			<Cropper
			  src={image}
			  style={{ height: 400, width: "100%" }}
			  initialAspectRatio={2 / 3}
			  aspectRatio={2 / 3}
			  strict={true}
			  autoCropArea={1}
			  preview=".img-preview"
			  guides={false}
			  ref={cropperRef}
			  crop={onCrop}
			/>
			<p>Crop box: {dimensions.width}x{dimensions.height}</p>
			<div style={{display: 'flex', gap: '10px'}}>
			  <div className="btn-group">
				<button onClick={() => moveCrop(0, -10)} className="btn btn-primary">
				  <FontAwesomeIcon icon={faArrowUp} style={{color: 'white'}} />
				</button>
				<button onClick={() => moveCrop(0, 10)} className="btn btn-primary">
				  <FontAwesomeIcon icon={faArrowDown} style={{color: 'white'}} />
				</button>
				<button onClick={() => moveCrop(-10, 0)} className="btn btn-primary">
				  <FontAwesomeIcon icon={faArrowLeft} style={{color: 'white'}} />
				</button>
				<button onClick={() => moveCrop(10, 0)} className="btn btn-primary">
				  <FontAwesomeIcon icon={faArrowRight} style={{color: 'white'}} />
				</button>
			  </div>
			  <div className="btn-group">
				<button onClick={() => zoomCrop(0.1)} className="btn btn-primary">
				  <FontAwesomeIcon icon={faPlus} style={{color: 'white'}} />
				</button>
				<button onClick={() => zoomCrop(-0.1)} className="btn btn-primary">
				  <FontAwesomeIcon icon={faMinus} style={{color: 'white'}} />
				</button>
			  </div>
			  <div className="btn-group">
				<button onClick={() => rotateCrop(-5)} className="btn btn-primary">
				  <FontAwesomeIcon icon={faUndo} style={{color: 'white'}} />
				</button>
				<button onClick={() => rotateCrop(5)} className="btn btn-primary">
				  <FontAwesomeIcon icon={faRedo} style={{color: 'white'}} />
				</button>
			  </div>
			  <div className="btn-group">
				 <button onClick={() => setAspectRatio(2 / 3)} className="btn btn-primary">2:3</button>
				 <button onClick={() => setAspectRatio(NaN)} className="btn btn-primary">Free</button>
			     <button onClick={resetCrop} className="btn btn-secondary">Reset Crop</button>
			  </div>
			</div>
		  </>
		)}
		{croppedImage && (
	      <div className="mt-3">
			<h3>Preview</h3>
			<img src={croppedImage} alt="Cropped preview" style={{ maxWidth: "100%" }} className="img-fluid" />
            <p>Dimensions: {dimensions.width}x{dimensions.height}</p>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                placeholder="File name"
              />
              <span className="input-group-text">.jpg</span>
            </div>
            <a href={downloadUrl} download={`${fileName}.jpg`} className="btn btn-primary mt-2">Download</a>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;